import React from 'react';
import { FaBookReader, FaRegBookmark } from "react-icons/fa";
import TimelineItem from './TimelineItem';
import SkillItem from './SkillItem';

const Resume = () => {
  return (
    <section>
      <header>
        <h2 className="h2 article-title">Resume</h2>
      </header>

      <div className="timeline">
        <div className="title-wrapper">
          <div className="icon-box">
            <FaBookReader />
          </div>
          <h3 className="h3">Education</h3>
        </div>
        <ol className="timeline-list">
          <TimelineItem
            title="Universitas Pembangunan Nasional Veteran Jakarta"
            date="2012 — 2017"
            description=""
          />
          <TimelineItem
            title="SMAN 1 Tajur Halang Bogor"
            date="2009 — 2012"
            description=""
          />
        </ol>
      </div>

      <div className="timeline">
        <div className="title-wrapper">
          <div className="icon-box">
            <FaRegBookmark />
          </div>
          <h3 className="h3">Experience</h3>
        </div>
        <ol className="timeline-list">
          <TimelineItem
            title="Senior Programmer"
            date="2020 — Present"
            description="As a programmer, I write, test, and maintain code to create and improve software applications. I solve problems through coding, develop applications for various platforms, and aim to build reliable, user-friendly solutions."
          />
          <TimelineItem
            title="Junior Mobile Developer"
            date="2018 — 2020"
            description="A Junior Mobile Developer develops, tests, maintains mobile applications, and fixes bugs."
          />
          <TimelineItem
            title="Data Warehouse Administrator"
            date="2017 — 2018"
            description="A Data Warehouse Administrator manages and maintains data warehouses, ensuring data integrity, performance, and security. They optimize data storage, oversee ETL processes, and support data analysis for business intelligence."
          />
        </ol>
      </div>

      <div className="skill">
        <h3 className="h3 skills-title">My skills</h3>
        <ul className="skills-list content-card">
          <SkillItem title="JavaScript" value={95} />
          <SkillItem title="React" value={95} />
          <SkillItem title="Web Design" value={85} />
          <SkillItem title="WordPress" value={80} />
        </ul>
      </div>
    </section>
  );
};

export default Resume;
