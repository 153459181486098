import { useState } from "react";
import { GiClawSlashes, GiMailbox } from "react-icons/gi";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const Aside = () => {
  const [open, setOpen] = useState(false);

  return (
    <aside className="sidebar">
      <div className="sidebar-info">
        <figure className="avatar-box">
          <img
            src={"/images/nuriman.jpeg"}
            alt="Richard hanrick"
            width="100"
          />
        </figure>

        <div className="info-content">
          <h1 className="name" title="Richard hanrick">
            Nur Iman
          </h1>

          <p className="title">Web developer</p>
        </div>

        <button className="info_more-btn" data-sidebar-btn onClick={() => setOpen(!open)}>
          <span>Show Contacts</span>
          <GiClawSlashes />
        </button>
        <div className="dropdown" style={{ display: open ? 'flex' : 'none' }}>
          <DropDownMenu />
        </div>
      </div>

      <div className="sidebar-info_more">
        <div className="separator"></div>

        <DropDownMenu />

        <div className="separator"></div>

        <ul className="social-list">
          <li className="social-item">
            <button className="social-link" onClick={() => window.location.href = 'https://www.facebook.com'}>
              <ion-icon name="logo-facebook"></ion-icon>
            </button>
          </li>

          <li className="social-item">
            <button className="social-link" onClick={() => window.location.href = 'https://www.twitter.com'}>
              <ion-icon name="logo-twitter"></ion-icon>
            </button>
          </li>

          <li className="social-item">
            <button className="social-link" onClick={() => window.location.href = 'https://www.instagram.com'}>
              <ion-icon name="logo-instagram"></ion-icon>
            </button>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Aside;

function DropDownMenu() {
  return (
    <ul className="contacts-list">
      <li className="contact-item">
        <div className="icon-box">
          <GiMailbox />
        </div>

        <div className="contact-info">
          <p className="contact-title">Email</p>
          <a href="mailto:nuriman8171@gmail.com" className="contact-link">
            nuriman8171@gmail.com
          </a>
        </div>
      </li>

      <li className="contact-item">
        <div className="icon-box">
          <FaWhatsapp />
        </div>

        <div className="contact-info">
          <p className="contact-title">Whatsapp</p>
          <a href="https://api.whatsapp.com/send/?phone=628128251993&text&type=phone_number&app_absent=0" className="contact-link">
            +62 (812)-825-1993
          </a>
        </div>
      </li>

      <li className="contact-item">
        <div className="icon-box">
          <FaLinkedin />
        </div>

        <div className="contact-info">
          <p className="contact-title">Linkedin</p>
          <a href="https://www.linkedin.com/in/nuriman875/" className="contact-link">
            nuriman875
          </a>
          {/* <time dateTime="1993-12-2">2 Desember 1993</time> */}
        </div>
      </li>

      <li className="contact-item">
        <div className="icon-box">
          <FaInstagram />
        </div>

        <div className="contact-info">
          <p className="contact-title">Instagram</p>
          <a href="https://www.instagram.com/imanmen_875/" className="contact-link">
            imanmen_875
          </a>
        </div>
      </li>
    </ul>
  );
}
