import React, { useRef } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_5mnkhts',      // Service ID
        'template_gdawprv',     // Template ID
        form.current,           // Form reference
        'A3LRcJBvN15SOPMXm'     // Public key
      )
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      )
      .catch((error) => {
        console.error('Error occurred while sending email:', error);
      });
  };

  return (
    <section className="contact" data-page="contact">

      <header>
        <h2 className="h2 article-title">Contact</h2>
      </header>

      <section className="mapbox" data-mapbox>
        <figure>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.543454774402!2d106.74313908848467!3d-6.45260424642213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e9005f638a69%3A0x1cc6f6ee957a80e5!2sImanmen_875!5e0!3m2!1sen!2sbd!4v1717934841012!5m2!1sen!2sbd"
            width="400" height="300" loading="lazy" title="Google Map"></iframe>
        </figure>
      </section>

      <section className="contact-form">
        <h3 className="h3 form-title">Contact Form</h3>

        <form action="#" className="form" ref={form} onSubmit={sendEmail}>
          <div className="input-wrapper">
            <input type="text" name="your_name" className="form-input" placeholder="Full name" required data-form-input />
            <input type="email" name="your_email" className="form-input" placeholder="Email address" required data-form-input />
          </div>

          <textarea name="message" className="form-input" placeholder="Your Message" required data-form-input></textarea>

          <button className="form-btn" type="submit" data-form-btn>
            <ion-icon name="paper-plane"></ion-icon>
            <span>Send Message</span>
          </button>
        </form>
      </section>

    </section>
  );
};

export default Contact;
