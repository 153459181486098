/* eslint-disable react/no-unescaped-entities */

import Service from "./Service";

const servicesData = [
  {
    icon: "/images/icon-design.svg",
    title: "Web design",
    description: "The most modern and high-quality design made at a professional level."
  },
  {
    icon: "/images/icon-dev.svg",
    title: "Web development",
    description: "High-quality development of sites at the professional level."
  },
  {
    icon: "/images/icon-app.svg",
    title: "Mobile apps",
    description: "Professional development of applications for iOS and Android."
  },
  {
    icon: "/images/icon-photo.svg",
    title: "Photography",
    description: "I make high-quality photos of any category at a professional level."
  }
];

const About = () => {
  return (
    <article className="about active" data-page="about">
      <header>
        <h2 className="h2 article-title">About me</h2>
      </header>

      <section className="about-text">
        <p>
          Hi! I'm Nur Iman, a Front-End Developer based in Bogor, Indonesia.
          I thrive on transforming complex problems into clear, beautiful, and user-friendly designs.
          My focus is on crafting functional and visually appealing websites that deliver a seamless user experience.
        </p>

        <p>
          As a senior programmer, I bring a personal touch to each project, ensuring an intuitive and engaging user interface.
          My goal is to creatively communicate your brand's message and identity through a website that resonates with your target audience.
        </p>
      </section>

      <section className="service">
        <h3 className="h3 service-title">What I'm doing</h3>

        <ul className="service-list">
          {servicesData.map((service, index) => (
            <Service
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
            />
          ))}
        </ul>
      </section>

      <section className="clients">
        <h3 className="h3 clients-title">Partner</h3>

        <ul className="clients-list has-scrollbar">
          <li className="clients-item">
            <a href="#0">
              <img src="images/logo1.svg" alt="client logo" />
            </a>
          </li>

          <li className="clients-item">
            <a href="#0">
              <img src="images/logo2.svg" alt="client logo" />
            </a>
          </li>

          <li className="clients-item">
            <a href="#0">
              <img src="images/logo3.png" alt="client logo" />
            </a>
          </li>

          <li className="clients-item">
            <a href="#0">
              <img src="images/logo4.png" alt="client logo" />
            </a>
          </li>

          <li className="clients-item">
            <a href="#0">
              <img src="images/logo5.png" alt="client logo" />
            </a>
          </li>

        </ul>
      </section>
    </article>
  );
}

export default About;
