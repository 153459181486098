import React from "react";
import styles from './styles.module.css'; // Untuk styling modal

const Modal = ({ showModal, closeModal, src }) => {
  if (!showModal) {
    return null; // Tidak akan menampilkan apa pun jika modal tidak aktif
  }

  return (
    <div className={styles['modal-overlay']} onClick={closeModal}>
      <div className={styles['modal-content']} onClick={(e) => e.stopPropagation()}>
        <img alt="img" im src={src} />
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default Modal;